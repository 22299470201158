<template>
    <div>
        <CContainer fluid>
            <CRow>
                <CCol sm="12">

                    <main class="main pt-5">
                        <div class="cabezal">
                           
                            <h1>Terms &amp; Conditions</h1>
                        </div>
                        <div class="contenido_terms">
                            
                            <h2>AIRPORT STANDARD CURBSIDE PICK UP PROCEDURES:</h2>
                            <p>At your arrival, you will receive a text from your driver:<br>
                                Please text your driver when you have just Landed and when you are  walking out and tell him<br>
                                YOUR LOCATION DETAILS:<br>
                                Like: Terminal / level / gate /passenger pick up letter or number
                            </p>
                            <h2>Our fares include:</h2>
                            <p>-Tolls included <br>
                                -Taxes Included<br>
                                -Fees included<br>
                                -Waiting time domestic flight 30 minutes included<br>
                                -Waiting time international flights 60 minutes included<br>
                                -Standard Pick Up: curbside (outside terminal with cellphone necessary)<br>
                                -Inside Pick Up with sign: (at request) included<br>
                                -Driver Tip: optional up to the passenger<br>
                            </p>
                            <h2>Baby Car Seats:</h2>
                            <p>We at UP LIMO Limo has made arrangements to provide customers with baby seats if customers failed to bring their own. We charge $15 for one baby seat. Customers are requested to mention the requirement of a baby seat at the time of booking the car.</p>
                            <h2>NOTICE: We are not responsible for direct private agreements between Passengers and Drivers for transportations.</h2>
                            <p>-They can overcharge you<br>
                                -No show on time<br>
                                -Insurance or document issues<br>
                                -Lost items<br>
                                -No help in mechanical issues on the route<br>
                                -ETC<br>
                            </p>
                            <h2>CAREFULLY READ BELOW-MENTIONED TERMS AND CONDITIONS:</h2>
                            <h2>FREE Cancellation Fee:</h2>
                            <p>Cancellations must be made 3 hours prior to pick up time.<br>
                                Jobs not canceled on time will be charged accordingly to the job description or reservation.
                            </p>
                            <h2>For arriving passenger to airport terminals:</h2>
                            <p>MEET &amp; GREET INSIDE THE TERMINAL WITH A SIGN IS OPTIONAL<br>
                                (Recommended to overseas travelers without local line phone)<br>
                                Meet &amp; Greet inside Terminal is optional at request<br>
                                -International flights included 45 minutes waiting time<br>
                                -Domestic flights included 30 minutes waiting time
                            </p>
                            <h4>With notice in advance winter storm or extreme weather maybe it can be applied extra charge</h4>
                            <h2>YOU MUST ACCEPT THESE TERMS OF USE PRIOR TO USING OUR SERVICE.</h2>
                            <h4>IF YOU DON’T AGREE WITH OUR TERMS OF USE, KINDLY DON’T USE OUR SERVICE.  UP LIMO INC  SOLELY HOLDS THE RIGHT TO ADD, ALTER, MODIFY OR UPDATE THE TERMS OF USE. BY ACCEPTING UP LIMO INC TERMS OF USE, YOU AGREE TO FOLLOW SUCH CHANGES, ALTERATIONS OR UPDATES. </h4>
                            <h2>Reservations:</h2>
                            <p>We recommend that our clients reserve the car ahead of time in order to get a timely pickup.<br>
                                This reservation bears no additional charges from the clients. Cars can also be provided at a short notice through our UP LIMO Limo App or via call.
                            </p>
                            <h2>No-Shows:</h2>
                            <p> You are requested to contact us at +1-866-590-5444 if they don’t see the driver at their defined pickup point. We at UP LIMO Limo will sort out the matter at once. If you leave the pickup point without notifying us, then you will be billed for the full price of the ride. This is referred to No-Show Fee. The waiting time for the driver after the scheduled pickup time is 30 minutes at regular pickups and 60 minutes at airport pickups. We will try to contact you on the contact numbers provided by you, and if you didn’t respond, then we will have no other choice but to release the driver. You will have to pay the full price of the ride, referred to as ‘No-Show’ fee along with waiting charges (if any). In case, you are not able to locate the driver at the pickup point, then immediately contact us in order to avoid ‘No-Show’ fee.</p>
                            <h2>Damages &amp; Cleaning Fee Incurred To The Car From You</h2>
                            <p>You will be held responsible if any damage and mess is made to the vehicle by your act. You must pay the charges to fix all the damages done by you and bring the vehicle back to its working state. We will charge up to $250 for cleaning.</p>
                            <h2>Credit Card Transactions: </h2>
                            <p>A certain authorization amount will be taken from you, which is higher than the real price of the ride. The authorization amount will be shown as pending funds on your credit card account. This amount is taken on the account if you wish to make any additional stops during the ride or change your destination. The additional fee will be charged accordingly, and all the receipts sent on your email.</p>
                            <h2>Disputes:</h2>
                            <p>For corporate clients: In case of any dispute, you are requested to notify us within 10 days from the date you receive the invoice from us. The matter of dispute needs to be mailed or faxed and it should be indicated properly in order to fix the dispute quickly. No refunds would be made for the disputes that are initiated after the allowed time frame. For individual clients: The dispute has to be filed within 15 days from the date of getting charged for the service, or the credit card statement of the client will be considered correct. You are requested to send the copies of credit card statement via mail or fax. Disputes filed after 15 days of availing the service will not be entertained, and the charges will not be refunded.</p>
                            <h2>Collection Fees: </h2>
                            <p>In non-payment cases, you agree to pay the litigation fees, accounting fees, legal counseling fees and all other expenses before, during and after the legal proceedings to collect payments.</p>
                            <h2>Rates Changes:</h2>
                            <p>UP LIMO Limo holds the right to change the price of any service at any time without giving any prior notice to the customers. Customers have to agree upon the changes in the prices. 
                                Itemized Invoices: 
                                Itemized invoices will be sent bi-weekly or on a monthly basis to the corporate clients with established corporate accounts. Whereas for individual accounts, itemized invoices will only be sent when customers request for the same.
                            </p>
                            <h2>Payment Due Date: </h2>
                            <p>For corporate clients: All payments must be cleared before 15 days from the date the invoice has been generated. Payments received after the given time-frame will occur interest charges @ 1.5% monthly and 18% yearly. 
                                For individual clients: People having personal profiles should send payments right after the ride is over and they reach the destination. Clients having credit cards will be charged through their credit cards only.
                            </p>
                            <h2>Point To Point Fare:</h2>
                            <p>The prices that are calculated based on the zones and mileage of the cars come in point to point fare. There are certain discounts for corporate clients who meet the minimum usage requirements. But, we suggest to kindly consult with the sales personnel in order to find the special rates.</p>
                            <h2>Hourly Rates: </h2>
                            <p>UP LIMO Limo offers a host of vehicles that can be rented on an hourly basis, but there are certain restrictions applied regarding specific zones.</p>
                            <h2>Waiting Time: </h2>
                            <p>The waiting time for domestic airport rides is 45 minutes and 60 minutes for international airport rides because of the delays in the arrival of the flight. We also offer a 10-minute grace period for all other pickups. After 10 minute grace period, charges will be applied to the customers. 
                                UP LIMO Limo keeps a close look at all domestic and international flights before sending the driver to the airport. The billing starts once the grace period from the (ETA) is over. 
                                There is no grace period for train-station pickups, as it is not possible for us to monitor the schedule of trains, so the billing starts as per the schedule pickup time.
                            </p>
                            <h2>Stops:</h2>
                            <p>Customers are requested to pay extra charges if they stop the vehicle multiple times during the journey. For precise details about pricing, kindly get in touch with our billing department.</p>
                            <h2>Tolls:</h2>
                            <p>Tolls are included.</p>
                            <h2>Meet &amp; Greet:</h2>
                            <p>
                                <!--$20 charge will be incurred from the customers for ‘meet and greet’ airport service who opted for Sedan's, Limousines, SUVs or Vans. Waiting time charges will also be charged from the customers.--> We properly instruct our drivers to meet the customers at the exit of the customs or at the baggage claim point. Our drivers have a board with the name of the customer to make things faster and easier. This package includes the grace period of 30 minutes for domestic flights and 45 minutes for international flights from the time of arrival.
                            </p>
                            <h2>Parking: </h2>
                            <p>The parking fees will be added into the customer’s account, which is around $8-16 based on the time of parking. This fee is not charged by us, but it goes straight into the airport parking lots.</p>
                            <h2>Gratuity: </h2>
                            <p>Gratuity is not  included in trip for UP LIMO Limo, Customers can pay additional tip to the drivers if they want.</p>
                            <h2>Baby Car Seats:</h2>
                            <p> We at UP LIMO Limo has made arrangements to provide customers with baby seats if customers failed to bring their own. We charge $15 for one baby seat. Customers are requested to mention the requirement of a baby seat at the time of booking the car. </p>
                        </div>
                    </main>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
    h2 {
        margin-top: 35px;
    }
</style>